var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-content" }, [
    _c("div", { staticClass: "inner-options" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("site.ui.property_filter.city")))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "location-options" },
        _vm._l(_vm.sortedObjects, function (city) {
          return _c("div", { key: city.id, staticClass: "item" }, [
            _c("div", {
              staticClass: "check",
              class: {
                checked:
                  _vm.$store.state.filter.currentFilter.city.indexOf(
                    city.id.toString()
                  ) > -1,
              },
              on: {
                click: function ($event) {
                  _vm.$emit("toggle-city", city.id.toString())
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "name" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(city.name) +
                  "\n                "
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }