<template>
    <div class="tab-content">
        <div class="inner-options">
            <div class="title">
                <span>{{ $t('site.ui.property_filter.city') }}</span>
            </div>
            <div class="location-options">
                <div
                    v-for="city in sortedObjects"
                    :key="city.id"
                    class="item"
                >
                    <div
                        class="check"
                        :class="{'checked': $store.state.filter.currentFilter.city.indexOf(city.id.toString()) > -1}"
                        @click="$emit('toggle-city', city.id.toString())"
                    />
                    <div class="name">
                        {{ city.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "keen-slider/keen-slider.min.css";

export default {
    props: {
        region: {
            type: Number,
            required: true
        },
        cityOptions: {
            type: Array,
            default: []
        },
        preparedConditions: {
            type: Array,
            default: []
        }
    },
    computed: {
        sortedObjects() {
            return [...this.cityOptions].sort((a, b) => a.name.localeCompare(b.name));
        },
    },
    data: () => ({
        selected: [],
    }),
}
</script>
