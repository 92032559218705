<template>
    <div
        class="property-selector-container"
        data-aos="fade-in"
    >
        <div
            id="property-selector-immobilie"
            class="property-selector property-selector-immobile container"
        >
            <div class="topline">
                <div class="num">
                    I
                </div>
                <div class="line"/>
                <p class="topline-title">
                    {{ $t('site.ui.property_filter.property_type') }}
                </p>
                <b-button
                    v-b-toggle:immobile-collapse
                    variant="info"
                    class="open-prop"
                >
                    <span class="when-open"><i class="cli cli-arrow-up"/></span>
                    <span class="when-closed"><i class="cli cli-arrow-down"/></span>
                </b-button>
            </div>
            <b-collapse
                id="immobile-collapse"
                :visible="steps.propertyType"
                @show="steps.propertyType = true"
                @hide="steps.propertyType = false"
            >
                <div>
                    <b-tabs>
                        <b-tab
                            active
                            class="hidden"
                        />
                        <b-tab
                            v-for="category in categoryOptions"
                            :key="category.id"
                            :title-link-class="{'option-active': $store.state.filter.currentFilter.category.indexOf(category.id.toString()) > -1}"
                            @click="toggleCategory(category.id.toString())"
                        >
                            <template #title>
                                <div class="img-container">
                                    <b-img-lazy
                                        :src="category.backgroundImageFilterThumbnail"
                                        :alt="category.name"
                                    />
                                </div>
                                <animated-icon
                                    v-if="$store.state.filter.currentFilter.category.indexOf(category.id.toString()) > -1"
                                    :icon-holder-id="'category-' + category.id"
                                    :svg-code="category.svgCode"
                                />
                                <p class="title">
                                    {{ category.name }}
                                </p>
                            </template>
                        </b-tab>
                    </b-tabs>
                </div>
            </b-collapse>
        </div>
        <div
            id="property-selector-region"
            class="property-selector property-selector-region container"
        >
            <div class="topline">
                <div class="num">
                    II
                </div>
                <div class="line"/>
                <p class="topline-title">
                    {{ $t('site.ui.property_filter.region') }}
                </p>
                <b-button
                    v-b-toggle:region-collapse
                    variant="info"
                    class="open-prop"
                >
                    <span class="when-open"><i class="cli cli-arrow-up"/></span>
                    <span class="when-closed"><i class="cli cli-arrow-down"/></span>
                </b-button>
            </div>
            <b-collapse
                id="region-collapse"
                :visible="steps.regionAndCity"
                @show="steps.regionAndCity = true"
                @hide="steps.regionAndCity = false"
            >
                <div>
                    <b-tabs>
                        <b-tab
                            :active="$store.state.filter.currentFilter.region == null"
                            class="hidden"
                        />
                        <b-tab
                            v-for="region in regionOptions"
                            :key="region.id"
                            :active="$store.state.filter.currentFilter.region == region.id"
                            :title-link-class="{'option-active': $store.state.filter.currentFilter.region == region.id, 'has--city-options': shouldShowCityOptionsSelect}"
                            @click="toggleRegion(parseInt(region.id))"
                        >
                            <template #title>
                                <div class="img-container">
                                    <b-img-lazy
                                        :src="region.backgroundImageFilterThumbnail"
                                        :alt="region.name"
                                    />
                                </div>
                                <animated-icon
                                    v-if="$store.state.filter.currentFilter.region == region.id"
                                    :icon-holder-id="'region-' + region.id"
                                    :svg-code="region.svgCode"
                                />
                                <p class="title">
                                    {{ region.name }}
                                </p>
                            </template>
                        </b-tab>
                    </b-tabs>
                </div>
                <city-options
                    v-if="shouldShowCityOptionsSelect"
                    :region="getCurrentFilter.region"
                    :class="['property-selector-region__options', {'is--disabled': !showSlider}]"
                    :city-options="cityOptions"
                    :prepared-conditions="conditionsToDisplay"
                    @toggle-city="toggleCity"
                    @slider-changed="onSliderChanged"
                />
<!--                <city-options-slider-->
<!--                    v-if="shouldShowCityOptionsSelect"-->
<!--                    :region="getCurrentFilter.region"-->
<!--                    :class="['property-selector-region__slider', {'is&#45;&#45;disabled': !showSlider}]"-->
<!--                    :city-options="cityOptions"-->
<!--                    :prepared-conditions="conditionsToDisplay"-->
<!--                    @toggle-city="toggleCity"-->
<!--                    @slider-changed="onSliderChanged"-->
<!--                />-->
            </b-collapse>
        </div>
        <div
            id="property-selector-budget"
            class="property-selector property-selector-budget container"
        >
            <div class="topline">
                <div class="num">
                    III
                </div>
                <div class="line"/>
                <p class="topline-title">
                    {{ $t('site.ui.property_filter.budget') }}
                </p>
                <b-button
                    v-b-toggle:budget-collapse
                    variant="info"
                    class="open-prop"
                >
                    <span class="when-open"><i class="cli cli-arrow-up"/></span>
                    <span class="when-closed"><i class="cli cli-arrow-down"/></span>
                </b-button>
            </div>
            <b-collapse
                id="budget-collapse"
                :visible="steps.budget"
                @show="steps.budget = true"
                @hide="steps.budget = false"
            >
                <div>
                    <b-tabs>
                        <b-tab
                            active
                            class="hidden"
                        />
                        <b-tab
                            :title-link-class="{'option-active': selectedBudgetType === 'step_1', 'budget-step1': true}"
                            :active="selectedBudgetType === 'step_1'"
                            @click="selectBudget(minValue,100000000)"
                        >
                            <template #title>
                                <div class="img-container"/>
                                <p class="title">
                                    {{ $t('site.ui.property_filter_option.step_1') }}
                                </p>
                            </template>
                        </b-tab>
                        <b-tab
                            :title-link-class="{'option-active': selectedBudgetType === 'step_2', 'budget-step2': true}"
                            :active="selectedBudgetType === 'step_2'"
                            @click="selectBudget(100000000, 200000000)"
                        >
                            <template #title>
                                <div class="img-container"/>
                                <p class="title">
                                    {{ $t('site.ui.property_filter_option.step_2') }}
                                </p>
                            </template>
                        </b-tab>
                        <b-tab
                            :title-link-class="{'option-active': selectedBudgetType === 'step_3', 'budget-step3': true}"
                            :active="selectedBudgetType === 'step_3'"
                            @click="selectBudget(minValue,300000000)"
                        >
                            <template #title>
                                <div class="img-container"/>
                                <p class="title">
                                    {{ $t('site.ui.property_filter_option.step_3') }}
                                </p>
                            </template>
                        </b-tab>
                        <b-tab
                            :title-link-class="{'option-active': selectedBudgetType === 'step_4', 'budget-step4': true}"
                            :active="selectedBudgetType === 'step_4'"
                            @click="selectBudget(300000000,500000000)"
                        >
                            <template #title>
                                <div class="img-container"/>
                                <p class="title">
                                    {{ $t('site.ui.property_filter_option.step_4') }}
                                </p>
                            </template>
                        </b-tab>
                        <b-tab
                            :title-link-class="{'option-active': selectedBudgetType === 'step_5', 'budget-step5': true}"
                            :active="selectedBudgetType === 'step_5'"
                            @click="selectBudget(500000000, maxValue)"
                        >
                            <template #title>
                                <div class="img-container"/>
                                <p class="title">
                                    {{ $t('site.ui.property_filter_option.step_5') }}
                                </p>
                            </template>
                        </b-tab>
                        <b-tab
                            title-item-class="budget-step_all"
                            :title-link-class="{'option-active': selectedBudgetType === 'step_all', 'budget-step_all': true}"
                            :active="selectedBudgetType === 'step_all'"
                            @click="selectBudget(minValue, maxValue)"
                        >
                            <template #title>
                                <div class="img-container"/>
                                <p class="title">
                                    {{ $t('site.ui.property_filter_option.step_all') }}
                                </p>
                            </template>
                        </b-tab>
                    </b-tabs>
                </div>
            </b-collapse>
        </div>
        <div class="btn-wrap">
            <div
                class="reset-filter"
                @click="resetFilter()"
            >
                {{ $t('site.ui.property_filter.reset') }}
            </div>
            <b-button
                :variant="isSecretSales ? 'secondary' : 'primary'"
                @click="submitFilter()"
            >
                {{ $t('site.ui.property_filter.show_results_button', {count: totalResultsCount}) }}
            </b-button>
        </div>
    </div>
</template>

<script>
import AnimatedIcon from "./AnimatedIcon";
import queryString from 'query-string';
import {mapGetters} from 'vuex';
import {EventBus} from '../event_bus'

const FILTER_FORWARD_DELAY = 1000;

export default {
    components: {
        AnimatedIcon
    },
    props: {
        standalone: {
            type: Boolean,
            default: false
        },
        apiEndpoint: {
            type: String,
            default: null
        },
        currentFilter: {
            type: Object,
            default: {}
        },
        preparedConditions: {
            type: Array,
            default: []
        },
        redirectOnFilterSubmit: {
            type: Boolean,
            default: false
        },
        filterRedirectTarget: {
            type: String,
            default: null
        },
        allCollapsed: {
            type: Boolean,
            default: false
        },
        allOpen: {
            type: Boolean,
            default: false
        },
        totalCount: {
            type: Number | String,
            default: 0
        },
        page: {
            type: [Number, String],
            default: 1
        },
        perPage: {
            type: [Number, String],
            default: 8
        },
        allRegions: {
            type: Array,
            default: []
        },
        allCategories: {
            type: Array,
            default: []
        },
        isSecretSales: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        slide: null,
        showSlider: false,
        steps: {
            propertyType: false,
            regionAndCity: false,
            budget: false
        },
        standalonePreparedConditions: [],
        totalResultsCount: 0,
        filter: null,
        requestLoading: false
    }),
    computed: {
        shouldShowCityOptionsSelect() {
            return (
                this.cityOptions.length > 0 &&
                this.getCurrentFilter.region != null &&
                this.getCurrentFilter.region != 14
            );
        },
        conditionsToDisplay() {
            if (this.standalone) {
                return this.standalonePreparedConditions;
            }

            return this.preparedConditions;
        },
        ...mapGetters([
            'getCurrentFilter'
        ]),
        selectedBudgetType() {
            if (this.getCurrentFilter.immoPriceMin === this.minValue && this.getCurrentFilter.immoPriceMax === 100000000) {
                return 'step_1';
            }

            if (this.getCurrentFilter.immoPriceMin === 100000000 && this.getCurrentFilter.immoPriceMax === 200000000) {
                return 'step_2';
            }

            if (this.getCurrentFilter.immoPriceMin === this.minValue && this.getCurrentFilter.immoPriceMax === 300000000) {
                return 'step_3';
            }

            if (this.getCurrentFilter.immoPriceMin === 300000000 && this.getCurrentFilter.immoPriceMax === 500000000) {
                return 'step_4';
            }

            if (this.getCurrentFilter.immoPriceMin === 500000000 && this.getCurrentFilter.immoPriceMax === this.maxValue) {
                return 'step_5';
            }

            return 'step_all';
        },
        maxValue() {
            let condition = this.getCondition('immoPrice');

            if (!condition) {
                return 0
            }

            if ('maxValue' in condition) {
                return parseInt(condition.maxValue);
            }

            return 0;
        },
        minValue() {
            let condition = this.getCondition('immoPrice');

            if (!condition) {
                return 0;
            }

            if ('minValue' in condition) {
                return parseInt(condition.minValue);
            }

            return 0;
        },
        currentValueMax() {
            return this.filter.immoPriceMax;
        },
        currentValueMin() {
            return this.filter.immoPriceMin;
        },
        regionOptions() {
            let regions = [];

            if (this.conditionsToDisplay) {
                for (let condition of this.conditionsToDisplay) {
                    if (condition.fieldName === 'region') {
                        regions = condition.objects.sort((a, b) => (a.priority > b.priority) ? -1 : 1);
                    }
                }
            }

            return this.mergeArrayObjects(regions, this.allRegions);
        },
        cityOptions() {
            let objects = [];

            if (this.conditionsToDisplay) {
                for (let condition of this.conditionsToDisplay) {
                    if (condition.fieldName === 'city') {
                        objects = condition.objects;
                    }
                }
            }

            return objects;
        },
        categoryOptions() {
            let categories = [];

            if (this.conditionsToDisplay) {
                for (let condition of this.conditionsToDisplay) {
                    if (condition.fieldName === 'category') {
                        categories = condition.objects.sort((a, b) => (a.priority > b.priority) ? -1 : 1);
                    }
                }
            }

            return this.mergeArrayObjects(categories, this.allCategories);
        }
    },
    watch: {
        allCollapsed(newVal, oldVal) {
            if (this.allCollapsed) {
                this.steps.propertyType = false;
                this.steps.regionAndCity = false;
                this.steps.budget = false;
            }
        }
    },
    created() {
        this.filter = this.getCurrentFilter;

        this.totalResultsCount = this.totalCount;

        if (this.standalone) {
            this.$store.commit('changeCurrentFilter', this.filter);
            this.steps.propertyType = true;
        } else {
            this.$store.commit('changeCurrentFilter', this.parseFilter(this.currentFilter));
        }
    },
    mounted() {
        if (this.allOpen) {
            this.steps.propertyType = true;
            this.steps.regionAndCity = true;
            this.steps.budget = true;
        }

        this.loadMetadata();

        EventBus.$once('property_search_reset', () => {
            console.log('reset filter')
            this.resetFilter();
        })
    },
    methods: {
        onSliderChanged() {
            this.showSlider = true;
        },
        mergeArrayObjects(arr1, arr2) {

            let finalArray = [...arr1, ...arr2];

            return finalArray.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === item.id
                    ))
            );
        },
        parseFilter(filter) {
            if (filter.region) {
                filter.region = parseInt(filter.region);
            }

            if (filter.immoPriceMin) {
                filter.immoPriceMin = parseInt(filter.immoPriceMin);
            }

            if (filter.immoPriceMax) {
                filter.immoPriceMax = parseInt(filter.immoPriceMax);
            }

            return filter;
        },
        getUrl(params, baseUrl = null) {

            let query = '?' + queryString.stringify(params, {
                arrayFormat: 'bracket'
            });

            if (baseUrl != null) {
                return baseUrl + query;
            }

            return location.protocol + '//' + location.host + location.pathname + query;
        }
        ,
        getCondition(fieldName) {
            if (this.conditionsToDisplay) {
                for (let condition of this.conditionsToDisplay) {
                    if (condition.fieldName === fieldName) {
                        return condition;
                    }
                }
            }

            return false;
        },
        submitFilter() {
            if (this.redirectOnFilterSubmit) {
                // build url with params and redirect
                let url = this.getUrl(this.getCurrentFilter, this.apiEndpoint)

                setTimeout(() => {
                    window.location.href = url;
                }, 300)
            }

            this.$emit('submit', this.getCurrentFilter);
        },
        getPropertyCountForCity(cityId) {
            if (this.preparedConditions) {
                for (let condition of this.preparedConditions) {
                    if (condition.fieldName === 'city') {
                        for (let value of condition.values) {
                            if (cityId === parseInt(value.value)) {
                                return value.count;
                            }
                        }
                    }
                }
            }

            return 0;
        },
        loadMetadata(initialLoad = false) {
            let self = this;

            let params = this.getCurrentFilter;

            this.requestLoading = true;

            axios.get(this.apiEndpoint, {
                params: {
                    ...params, ...{
                        onlyMetadata: true
                    }
                },
            })
                .then(res => res.data)
                .then(res => {
                    if (!initialLoad) {
                        this.filter = res.currentFilter;
                        this.totalResultsCount = res.totalCount;
                    }

                    if (this.standalone) {
                        this.totalResultsCount = res.totalCount;
                        this.filter = res.currentFilter;
                        this.standalonePreparedConditions = res.preparedConditions;
                    }

                    this.requestLoading = false;
                })
                .catch(err => {
                    console.error('could not load metadata', err)
                })
        },
        toggleCity(id) {
            if (this.filter.city.indexOf(id) > -1) {
                this.filter.city.splice(this.filter.city.indexOf(id), 1);
            } else {
                this.filter.city.push(id);
            }

            this.$store.commit('changeCity', this.filter.city);

            this.loadMetadata();
            this.emitChanges();
        },
        toggleCategory(id) {
            if (this.filter.category.indexOf(id) > -1) {
                this.filter.category.splice(this.filter.category.indexOf(id), 1);
            } else {
                this.filter.category.push(id);
            }

            if (this.filter.category.length === 1) {
                this.steps.regionAndCity = true;

                // setTimeout(() => {
                //     this.$scrollTo('#property-selector-immobilie', 1600);
                // }, FILTER_FORWARD_DELAY);
            }

            this.$store.commit('changeCategory', this.filter.category);

            this.loadMetadata();
            this.emitChanges();
        },
        toggleRegion(id) {
            if (this.filter.region == null) {
                this.steps.budget = true;
                // setTimeout(() => {
                //     this.$scrollTo('#property-selector-region', 1600);
                // }, FILTER_FORWARD_DELAY);
            }

            if (this.filter.region == id) {
                this.filter.region = null;
            } else {
                this.filter.region = id;
            }

            this.filter.city = [];

            this.showSlider = false;

            this.$store.commit('changeRegion', this.filter.region);
            this.$store.commit('changeCity', this.filter.city);

            this.loadMetadata();
            this.emitChanges();
        },
        selectBudget(min, max) {

            if (this.filter.immoPriceMin === min) {
                this.filter.immoPriceMin = null;
            } else {
                this.filter.immoPriceMin = min;
            }

            if (this.filter.immoPriceMax === max) {
                this.filter.immoPriceMax = null;
            } else {
                this.filter.immoPriceMax = max;
            }

            this.$store.commit('changeImmoPriceMax', max);
            this.$store.commit('changeImmoPriceMin', min);

            this.loadMetadata();
            this.emitChanges();
        },
        emitChanges() {
            this.$emit('input', this.filter);
            this.$emit('change', this.filter);
        },
        chunkArray(myArray, chunk_size) {
            var index = 0;
            var arrayLength = myArray.length;
            var tempArray = [];
            let myChunk = [];

            for (index = 0; index < arrayLength; index += chunk_size) {
                myChunk = myArray.slice(index, index + chunk_size);
                // Do something if you want with the group
                tempArray.push(myChunk);
            }

            return tempArray;
        },
        resetFilter() {
            this.filter = {
                category: [],
                region: null,
                city: [],
                immoPriceMin: null,
                immoPriceMax: null
            };

            this.$store.commit('changeCategory', this.filter.category);
            this.$store.commit('changeRegion', this.filter.region);
            this.$store.commit('changeCity', this.filter.city);
            this.$store.commit('changeImmoPriceMin', this.filter.immoPriceMin);
            this.$store.commit('changeImmoPriceMax', this.filter.immoPriceMax);

            this.steps.budget = false;
            this.steps.regionAndCity = false;

            // setTimeout(() => {
            //     this.$scrollTo('#property-selector-immobilie', 1600);
            // }, 1000);


            this.loadMetadata();
            this.emitChanges();
        }
    }
}
</script>

<style lang="scss">
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";


.property-selector-budget {
    .budget-step1 {
        .img-container {
            background: transparentize($navi, 0.5) !important;
        }
    }

    .budget-step2 {
        .img-container {
            background: transparentize($navi, 0.35) !important;
        }
    }

    .budget-step3 {
        .img-container {
            background: transparentize($navi, 0.25) !important;
        }
    }

    .budget-step4 {
        .img-container {
            background: transparentize($navi, 0.15) !important;
        }
    }

    .budget-step5 {
        .img-container {
            background: transparentize($navi, 0.05) !important;
        }
    }


    .nav-item {
        .img-container {
            &:before {
                transform: none !important;
            }
        }
    }
}

.property-selector-immobile {
    .nav-tabs {
        .nav-link {
            &.active {
                .img-container:before {
                    transform: none;
                }
            }
        }
    }
}

.property-selector {
    .topline {
        .topline-title {
            text-transform: uppercase;
        }
    }

    .nav-item {
        .nav-link {
            background: transparent;
        }
    }

    &.property-selector-budget {
        .nav-tabs {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            row-gap: 0;

            @include media-breakpoint-between(sm, md) {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }

            @include media-breakpoint-down(sm) {
                grid-template-columns: 1fr;
            }

            .nav-item {

                @include media-breakpoint-down(sm) {
                    padding-top: 82px;
                    order: 2;
                }

                &.budget-step_all {
                    padding-top: 62px;
                    grid-column: 1/-1;


                    @include media-breakpoint-down(sm) {
                        padding-top: 82px;
                        order: 1
                    }
                }
            }

            .nav-link {
                width: 172px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                &.budget-step_all {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;

                    .img-container {
                        opacity: 0.5;
                        background-color: rgba(31, 61, 69, 0.20);

                        &:before {
                            @include media-breakpoint-up(md) {
                                display: none;
                            }
                        }
                    }

                    .title {
                        color: $navi;

                        &:before, &:after {
                            border-color: transparentize($navi, 0.75);
                        }

                        @include media-breakpoint-up(md) {
                            margin: 0;

                            &:before, &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}


.property-selector-immobile,
.property-selector-region {
    &__slider {
        opacity: 1;
        transition: opacity 300ms ease;

        @include media-breakpoint-up(md) {
            &.is--disabled {
                opacity: 0;
            }
        }
    }

    .nav-item {
        .nav-link {
            background: transparent;

            .animated-icon {
                position: absolute;
                top: 45px;
                width: auto;
                left: 50%;
                transform: translateX(-50%);
                transition: ease-in-out 200ms all;
                opacity: 0;


                @include media-breakpoint-down(md) {
                    top: 20%;
                    height: 32px;
                }


                svg {
                    height: 65px;
                    width: auto;

                    @include media-breakpoint-down(md) {
                        height: 75px;
                    }

                    @include media-breakpoint-down(xs) {
                        height: 45px;
                    }
                }
            }

            .title {
                transition: ease-in-out 200ms all;
            }

            &.option-active {
                .title {
                    padding: 78px 28px 0;

                    @include media-breakpoint-down(sm) {
                        padding: 57px 28px 0;
                    }
                }

                .animated-icon {
                    opacity: 1;
                }
            }
        }
    }
}


.location-slider__control {
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.property-selector-container {
    .reset-filter {
        cursor: pointer;
        font-size: 14px;
        color: #888;
        margin-bottom: 15px;
    }
}

</style>
