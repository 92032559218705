var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-content" }, [
    _c("div", { staticClass: "inner" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("site.ui.property_filter.city")))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "location-slider__control slider-prev",
          on: {
            click: function ($event) {
              return _vm.slider.prev()
            },
          },
        },
        [_c("i", { staticClass: "cli-arrow-left-outline" })]
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "slider", staticClass: "location-slider keen-slider" },
        _vm._l(_vm.cityOptions, function (city) {
          return _c(
            "div",
            { key: city.id, staticClass: "item keen-slider__slide" },
            [
              _c("div", {
                staticClass: "check",
                class: {
                  checked:
                    _vm.$store.state.filter.currentFilter.city.indexOf(
                      city.id.toString()
                    ) > -1,
                },
                on: {
                  click: function ($event) {
                    _vm.$emit("toggle-city", city.id.toString())
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "name" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(city.name) +
                    "\n                "
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "location-slider__control slider-next",
          on: {
            click: function ($event) {
              return _vm.slider.next()
            },
          },
        },
        [_c("i", { staticClass: "cli-arrow-right-outline" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }